<template>
  <c-box
    display="flex"
    :pb="['47px', 0]"
    :minHeight="['100vh']"
    backgroundImage="linear-gradient(180deg, #008C817f 50%, #F4CC467f 100%), url(/images/bg-login.png)"
    backgroundRepeat="no-repeat"
    backgroundSize="cover"
    justifyContent="center"
    alignItems="center"
  >
    <c-box
      w="100%"
      background="#FFFFFF"
      box-shadow="9px 9px 10px rgba(0, 0, 0, 0.15)"
      border-radius="12px"
      maxWidth="588px"
      textAlign="center"
      py="75"
      px="55"
    >
      <c-image
        :src="iconVerify"
        alt="icon"
        mx="auto"
        marginBottom="74px"
        w="133px"
      />
      <c-text
        color="black.900"
        fontSize="24px"
        lineHeight="36px"
        fontWeight="600"
        marginBottom="14px"
        fontFamily="Montserrat"
      >
        Verifikasi {{ title }}
      </c-text>
      <c-text
        color="darkGray"
        fontSize="18px"
        lineHeight="27px"
        marginBottom="53px"
        fontFamily="Roboto"
        v-html="description"
      ></c-text>
      <c-button
        v-if="success"
        as="router-link"
        :to="{ name: 'auth.login' }"
        fontWeight="700"
        right-icon="arrow-forward"
        color="brand.900"
        variant="outline"
        borderColor="brand.900"
        borderRadius="200px"
        py="15px"
        px="30px"
        h="auto"
      >
        Mulai Dietela
      </c-button>
      <c-button
        v-if="!success"
        as="router-link"
        :to="{ name: 'auth.resend-verify' }"
        fontWeight="700"
        right-icon="arrow-forward"
        color="brand.900"
        variant="outline"
        borderColor="brand.900"
        borderRadius="200px"
        py="15px"
        px="30px"
        h="auto"
      >
        Kirim Ulang Verifikasi
      </c-button>
    </c-box>
  </c-box>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "VerifyingPage",
  data() {
    return {
      error: null,
      success: false,
    };
  },
  computed: {
    ...mapGetters(["axios"]),
    iconVerify() {
      if (this.success && !this.error) {
        return require("@/assets/icon-solid-check.svg");
      } else {
        return require("@/assets/icon-solid-close.svg");
      }
    },
    title() {
      if (this.success && !this.error) {
        return "Sukses";
      } else {
        return "Gagal";
      }
    },
    description() {
      if (this.success && !this.error) {
        return "Kamu telah berhasil melakukan verifikasi email. <br /> Klik tombol di bawah untuk memulai Dietela.";
      } else {
        return "Kamu tidak berhasil melakukan verifikasi email. <br /> Klik tombol di bawah untuk mengirim ulang email verifikasi.";
      }
    },
  },
  mounted() {
    let token = this.$route.query.token;
    if (!token) {
      this.success = false;
      this.error = null;
      return;
    }
    this.axios
      .post(`/v1/auth/verify-email`, { token })
      .then(() => {
        this.success = true;
        this.error = null;
      })
      .catch((err) => {
        let error = err.response.data.message;
        if (error.toLowerCase() === "user already verified") {
          this.$router.replace("/login");
        } else {
          this.error = err.response.data.message;
        }
      });
  },
};
</script>

<style></style>
